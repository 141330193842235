import request from '@/plugins/axios'

// ================分组管理==============
export function listApi(params) {
  // 分组列表
  return request({
    url: '/group/account_groups',
    method: 'get',
    params
  })
}
export function addApi(data) {
  // 分组创建
  return request({
    url: '/group/account_groups',
    method: 'post',
    data
  })
}
export function detailApi(id) {
  // 分组详情
  return request({
    url: `/group/account_groups/${id}`,
    method: 'get'
  })
}
export function putApi(id, data) {
  // 更新指定分组
  return request({
    url: `/group/account_groups/${id}`,
    method: 'patch',
    data
  })
}
export function delApi(id) {
  // 删除指定分组
  return request({
    url: `/group/account_groups/${id}`,
    method: 'delete'
  })
}
export function group_all_Api() {
  //获取所有分组
  return request({
    url: `/group/account_groups:get_all`
  })
}
// =================================添加分组========================
export function group_facebook_Api(id, data) {
  //facebook添加分组
  return request({
    url: `/facebook/accounts/${id}`,
    method: 'patch',
    data
  })
}
export function group_twitter_Api(id, data) {
  //twitter添加分组
  return request({
    url: `/twitter/accounts/${id}`,
    method: 'patch',
    data
  })
}
export function group_youtube_Api(id, data) {
  //youtube添加分组
  return request({
    url: `/youtube/accounts/${id}`,
    method: 'patch',
    data
  })
}
